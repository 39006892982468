/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @interface PointsConversionRequestResolutionDto
 */
export interface PointsConversionRequestResolutionDto {
    /**
     *
     * @type {string}
     * @memberof PointsConversionRequestResolutionDto
     */
    'resolution': PointsConversionRequestResolutionDtoResolutionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PointsConversionRequestResolutionDtoResolutionEnum {
    ACCEPT = 'ACCEPT',
    DECLINE = 'DECLINE'
}


