/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @interface ConversionProcessMetricDtoGroup
 */
export interface ConversionProcessMetricDtoGroup {
    /**
     *
     * @type {string}
     * @memberof ConversionProcessMetricDtoGroup
     */
    'conversionStatus'?: ConversionProcessMetricDtoGroupConversionStatusEnum;
    /**
     *
     * @type {number}
     * @memberof ConversionProcessMetricDtoGroup
     */
    'percentage'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ConversionProcessMetricDtoGroupConversionStatusEnum {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    DECLINED = 'DECLINED',
    EXPIRED = 'EXPIRED',
    FORCED = 'FORCED'
}


