/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @enum {string}
 */

export enum ErrorCode {
    MEMBER_TIER_LEVEL_IS_SET = 'MEMBER_TIER_LEVEL_IS_SET',
    MEMBER_TIER_LEVEL_IS_HIGHER = 'MEMBER_TIER_LEVEL_IS_HIGHER',
    CONSUMER_PHONE_INVALID_UPDATE = 'CONSUMER_PHONE_INVALID_UPDATE',
    CONSUMER_EMAIL_INVALID_UPDATE = 'CONSUMER_EMAIL_INVALID_UPDATE',
    CONSUMER_EMAIL_EXPIRED_CODE = 'CONSUMER_EMAIL_EXPIRED_CODE',
    CONSUMER_PHONE_PLIVO_EXCEEDED_ATTEMPTS = 'CONSUMER_PHONE_PLIVO_EXCEEDED_ATTEMPTS',
    CONSUMER_PHONE_PLIVO_EXPIRED_SESSION = 'CONSUMER_PHONE_PLIVO_EXPIRED_SESSION',
    CONSUMER_PHONE_PLIVO_INCORRECT_OTP = 'CONSUMER_PHONE_PLIVO_INCORRECT_OTP',
    CONSUMER_PHONE_PLIVO_NOT_EQUAL_REQUESTED_ONE = 'CONSUMER_PHONE_PLIVO_NOT_EQUAL_REQUESTED_ONE',
    CONSUMER_PHONE_PLIVO_NOT_VERIFIED = 'CONSUMER_PHONE_PLIVO_NOT_VERIFIED',
    BUSINESS_USER_EMAIL_INVALID_UPDATE = 'BUSINESS_USER_EMAIL_INVALID_UPDATE',
    BUSINESS_USER_EMAIL_EXPIRED_CODE = 'BUSINESS_USER_EMAIL_EXPIRED_CODE',
    NO_AVAILABLE_PHONE_NUMBERS_FOUND = 'NO_AVAILABLE_PHONE_NUMBERS_FOUND',
    PHONE_NUMBER_CREATE_FAILED = 'PHONE_NUMBER_CREATE_FAILED',
    PHONE_NUMBER_UNRENT_FAILED = 'PHONE_NUMBER_UNRENT_FAILED',
    BUSINESS_CAMPAIGN_SUBSCRIPTION_PLAN = 'BUSINESS_CAMPAIGN_SUBSCRIPTION_PLAN',
    BUSINESS_CAMPAIGN_LIMIT = 'BUSINESS_CAMPAIGN_LIMIT',
    BUSINESS_CAMPAIGN_INCORRECT_STATUS_UPDATE = 'BUSINESS_CAMPAIGN_INCORRECT_STATUS_UPDATE',
    BUSINESS_CAMPAIGN_COMPLETED = 'BUSINESS_CAMPAIGN_COMPLETED',
    BUSINESS_CAMPAIGN_NOT_DELETABLE = 'BUSINESS_CAMPAIGN_NOT_DELETABLE',
    BUSINESS_COLLABORATION_DISABLED = 'BUSINESS_COLLABORATION_DISABLED',
    BUSINESS_USES_DEFAULT_POINTS = 'BUSINESS_USES_DEFAULT_POINTS',
    BUSINESS_OFFER_STATUS = 'BUSINESS_OFFER_STATUS',
    BUSINESS_OFFER_TYPE = 'BUSINESS_OFFER_TYPE',
    BUSINESS_OFFER_MEMBER_MATCH = 'BUSINESS_OFFER_MEMBER_MATCH',
    BUSINESS_OFFER_MEMBER_MISSING = 'BUSINESS_OFFER_MEMBER_MISSING',
    BUSINESS_OFFER_STATUS_TRANSITION = 'BUSINESS_OFFER_STATUS_TRANSITION',
    BUSINESS_DEFAULT_POINTS_MISSING = 'BUSINESS_DEFAULT_POINTS_MISSING',
    BUSINESS_POINTS_REQUIRED = 'BUSINESS_POINTS_REQUIRED',
    BUSINESS_POINTS_NOT_UNIQUE = 'BUSINESS_POINTS_NOT_UNIQUE',
    REFERRAL_PROGRAM_EXISTS = 'REFERRAL_PROGRAM_EXISTS',
    BUSINESS_REWARD_DISCOUNT_CODES_CSV = 'BUSINESS_REWARD_DISCOUNT_CODES_CSV',
    BUSINESS_REWARD_CLAIM_BALANCE = 'BUSINESS_REWARD_CLAIM_BALANCE',
    BUSINESS_REWARD_CLAIM_MEMBERSHIP = 'BUSINESS_REWARD_CLAIM_MEMBERSHIP',
    BUSINESS_REWARD_CLAIM_COMPLETED = 'BUSINESS_REWARD_CLAIM_COMPLETED',
    BUSINESS_REWARD_CLAIM_DURATION = 'BUSINESS_REWARD_CLAIM_DURATION',
    BUSINESS_REWARD_CLAIM_GENDER = 'BUSINESS_REWARD_CLAIM_GENDER',
    BUSINESS_REWARD_CLAIM_TIER = 'BUSINESS_REWARD_CLAIM_TIER',
    BUSINESS_REWARD_CLAIM_AGE = 'BUSINESS_REWARD_CLAIM_AGE',
    BUSINESS_REWARD_STATUS_TRANSITION = 'BUSINESS_REWARD_STATUS_TRANSITION',
    BUSINESS_REWARD_STATUS = 'BUSINESS_REWARD_STATUS',
    BUSINESS_REWARD_TYPE = 'BUSINESS_REWARD_TYPE',
    BUSINESS_REWARD_LIMIT = 'BUSINESS_REWARD_LIMIT',
    BUSINESS_REWARD_LIMIT_REDUCED = 'BUSINESS_REWARD_LIMIT_REDUCED',
    BUSINESS_REWARD_LIMIT_DISCOUNT_CODES = 'BUSINESS_REWARD_LIMIT_DISCOUNT_CODES',
    BUSINESS_REWARD_DISCOUNT_CODES = 'BUSINESS_REWARD_DISCOUNT_CODES',
    BUSINESS_REWARD_DURATION = 'BUSINESS_REWARD_DURATION',
    BUSINESS_TIER_LEVEL_ID = 'BUSINESS_TIER_LEVEL_ID',
    BUSINESS_TIER_CONFIGURATION_EXISTS = 'BUSINESS_TIER_CONFIGURATION_EXISTS',
    BUSINESS_TIER_CONFIGURATION_STATUS = 'BUSINESS_TIER_CONFIGURATION_STATUS'
}



