/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @interface ClaimedRewardDto
 */
export interface ClaimedRewardDto {
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardId'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'businessId'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'status'?: ClaimedRewardDtoStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardType'?: ClaimedRewardDtoRewardTypeEnum;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardTitle'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardImageUrl'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardThumbnailUrl'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardCoverUrl'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardCreatedWhen'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardClaimedOn'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'expirationDate'?: string;
    /**
     *
     * @type {number}
     * @memberof ClaimedRewardDto
     */
    'rewardPrice'?: number;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardDescription'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardTermsAndConditions'?: string;
    /**
     *
     * @type {number}
     * @memberof ClaimedRewardDto
     */
    'rewardDiscountPercentage'?: number;
    /**
     *
     * @type {number}
     * @memberof ClaimedRewardDto
     */
    'rewardDollarDiscount'?: number;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardDiscountCode'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardPromoCode'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardFreeProductName'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardEventName'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardEventDateTime'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardEventLocation'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardEventPassPictureKey'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardTierUpgrade'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardDigitalContentUrl'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'rewardCustomRewardName'?: string;
    /**
     *
     * @type {string}
     * @memberof ClaimedRewardDto
     */
    'createdWhen'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ClaimedRewardDtoStatusEnum {
    CLAIMED = 'CLAIMED',
    REDEEMED = 'REDEEMED',
    EXPIRED = 'EXPIRED'
}
/**
    * @export
    * @enum {string}
    */
export enum ClaimedRewardDtoRewardTypeEnum {
    DISCOUNT_CODE = 'DISCOUNT_CODE',
    FREE_PRODUCT = 'FREE_PRODUCT',
    ACCESS_TO_EVENT = 'ACCESS_TO_EVENT',
    GIFT_CARD = 'GIFT_CARD',
    TIER_UPGRADE = 'TIER_UPGRADE',
    DIGITAL_CONTENT = 'DIGITAL_CONTENT',
    CUSTOM = 'CUSTOM'
}


