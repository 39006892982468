/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @interface MemberImportDto
 */
export interface MemberImportDto {
    /**
     *
     * @type {string}
     * @memberof MemberImportDto
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof MemberImportDto
     */
    'discrepanciesKey'?: string;
    /**
     *
     * @type {string}
     * @memberof MemberImportDto
     */
    'fileName'?: string;
    /**
     *
     * @type {number}
     * @memberof MemberImportDto
     */
    'addedMembers'?: number;
    /**
     *
     * @type {number}
     * @memberof MemberImportDto
     */
    'duplicatesDiscrepancies'?: number;
    /**
     *
     * @type {number}
     * @memberof MemberImportDto
     */
    'invalidDataDiscrepancies'?: number;
    /**
     *
     * @type {string}
     * @memberof MemberImportDto
     */
    'status'?: MemberImportDtoStatusEnum;
    /**
     *
     * @type {string}
     * @memberof MemberImportDto
     */
    'createdWhen'?: string;
    /**
     *
     * @type {string}
     * @memberof MemberImportDto
     */
    'updatedWhen'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberImportDtoStatusEnum {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED'
}


