/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @interface MembersJoiningTypeDataPointsMetricDtoTotal
 */
export interface MembersJoiningTypeDataPointsMetricDtoTotal {
    /**
     *
     * @type {string}
     * @memberof MembersJoiningTypeDataPointsMetricDtoTotal
     */
    'joiningType'?: MembersJoiningTypeDataPointsMetricDtoTotalJoiningTypeEnum;
    /**
     *
     * @type {number}
     * @memberof MembersJoiningTypeDataPointsMetricDtoTotal
     */
    'total'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MembersJoiningTypeDataPointsMetricDtoTotalJoiningTypeEnum {
    IN_APP = 'IN_APP',
    BUSINESS_LANDING = 'BUSINESS_LANDING',
    IN_STORE = 'IN_STORE'
}


