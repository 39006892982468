/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MemberNoteDto } from '../models';
// @ts-ignore
import { MemberNoteRequestDto } from '../models';
// @ts-ignore
import { ProblemDetail } from '../models';
/**
 * MemberNoteControllerApi - axios parameter creator
 * @export
 */
export const MemberNoteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} memberId
         * @param {MemberNoteRequestDto} memberNoteRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote: async (memberId: string, memberNoteRequestDto: MemberNoteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('createNote', 'memberId', memberId)
            // verify required parameter 'memberNoteRequestDto' is not null or undefined
            assertParamExists('createNote', 'memberNoteRequestDto', memberNoteRequestDto)
            const localVarPath = `/members/{memberId}/notes`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberNoteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} memberId
         * @param {string} noteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (memberId: string, noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('deleteNote', 'memberId', memberId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('deleteNote', 'noteId', noteId)
            const localVarPath = `/members/{memberId}/notes/{noteId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)))
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes: async (memberId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getNotes', 'memberId', memberId)
            const localVarPath = `/members/{memberId}/notes`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} memberId
         * @param {string} noteId
         * @param {MemberNoteRequestDto} memberNoteRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (memberId: string, noteId: string, memberNoteRequestDto: MemberNoteRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('updateNote', 'memberId', memberId)
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateNote', 'noteId', noteId)
            // verify required parameter 'memberNoteRequestDto' is not null or undefined
            assertParamExists('updateNote', 'memberNoteRequestDto', memberNoteRequestDto)
            const localVarPath = `/members/{memberId}/notes/{noteId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)))
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(memberNoteRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberNoteControllerApi - functional programming interface
 * @export
 */
export const MemberNoteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberNoteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} memberId
         * @param {MemberNoteRequestDto} memberNoteRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNote(memberId: string, memberNoteRequestDto: MemberNoteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNote(memberId, memberNoteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} memberId
         * @param {string} noteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(memberId: string, noteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(memberId, noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} memberId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotes(memberId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MemberNoteDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotes(memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} memberId
         * @param {string} noteId
         * @param {MemberNoteRequestDto} memberNoteRequestDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(memberId: string, noteId: string, memberNoteRequestDto: MemberNoteRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemberNoteDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(memberId, noteId, memberNoteRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberNoteControllerApi - factory interface
 * @export
 */
export const MemberNoteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberNoteControllerApiFp(configuration)
    return {
        /**
         *
         * @param {MemberNoteControllerApiCreateNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(requestParameters: MemberNoteControllerApiCreateNoteRequest, options?: AxiosRequestConfig): AxiosPromise<MemberNoteDto> {
            return localVarFp.createNote(requestParameters.memberId, requestParameters.memberNoteRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MemberNoteControllerApiDeleteNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(requestParameters: MemberNoteControllerApiDeleteNoteRequest, options?: AxiosRequestConfig): AxiosPromise<MemberNoteDto> {
            return localVarFp.deleteNote(requestParameters.memberId, requestParameters.noteId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MemberNoteControllerApiGetNotesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes(requestParameters: MemberNoteControllerApiGetNotesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<MemberNoteDto>> {
            return localVarFp.getNotes(requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MemberNoteControllerApiUpdateNoteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(requestParameters: MemberNoteControllerApiUpdateNoteRequest, options?: AxiosRequestConfig): AxiosPromise<MemberNoteDto> {
            return localVarFp.updateNote(requestParameters.memberId, requestParameters.noteId, requestParameters.memberNoteRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createNote operation in MemberNoteControllerApi.
 * @export
 * @interface MemberNoteControllerApiCreateNoteRequest
 */
export interface MemberNoteControllerApiCreateNoteRequest {
    /**
     *
     * @type {string}
     * @memberof MemberNoteControllerApiCreateNote
     */
    readonly memberId: string

    /**
     *
     * @type {MemberNoteRequestDto}
     * @memberof MemberNoteControllerApiCreateNote
     */
    readonly memberNoteRequestDto: MemberNoteRequestDto
}

/**
 * Request parameters for deleteNote operation in MemberNoteControllerApi.
 * @export
 * @interface MemberNoteControllerApiDeleteNoteRequest
 */
export interface MemberNoteControllerApiDeleteNoteRequest {
    /**
     *
     * @type {string}
     * @memberof MemberNoteControllerApiDeleteNote
     */
    readonly memberId: string

    /**
     *
     * @type {string}
     * @memberof MemberNoteControllerApiDeleteNote
     */
    readonly noteId: string
}

/**
 * Request parameters for getNotes operation in MemberNoteControllerApi.
 * @export
 * @interface MemberNoteControllerApiGetNotesRequest
 */
export interface MemberNoteControllerApiGetNotesRequest {
    /**
     *
     * @type {string}
     * @memberof MemberNoteControllerApiGetNotes
     */
    readonly memberId: string
}

/**
 * Request parameters for updateNote operation in MemberNoteControllerApi.
 * @export
 * @interface MemberNoteControllerApiUpdateNoteRequest
 */
export interface MemberNoteControllerApiUpdateNoteRequest {
    /**
     *
     * @type {string}
     * @memberof MemberNoteControllerApiUpdateNote
     */
    readonly memberId: string

    /**
     *
     * @type {string}
     * @memberof MemberNoteControllerApiUpdateNote
     */
    readonly noteId: string

    /**
     *
     * @type {MemberNoteRequestDto}
     * @memberof MemberNoteControllerApiUpdateNote
     */
    readonly memberNoteRequestDto: MemberNoteRequestDto
}

/**
 * MemberNoteControllerApi - object-oriented interface
 * @export
 * @class MemberNoteControllerApi
 * @extends {BaseAPI}
 */
export class MemberNoteControllerApi extends BaseAPI {
    /**
     *
     * @param {MemberNoteControllerApiCreateNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberNoteControllerApi
     */
    public createNote(requestParameters: MemberNoteControllerApiCreateNoteRequest, options?: AxiosRequestConfig) {
        return MemberNoteControllerApiFp(this.configuration).createNote(requestParameters.memberId, requestParameters.memberNoteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MemberNoteControllerApiDeleteNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberNoteControllerApi
     */
    public deleteNote(requestParameters: MemberNoteControllerApiDeleteNoteRequest, options?: AxiosRequestConfig) {
        return MemberNoteControllerApiFp(this.configuration).deleteNote(requestParameters.memberId, requestParameters.noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MemberNoteControllerApiGetNotesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberNoteControllerApi
     */
    public getNotes(requestParameters: MemberNoteControllerApiGetNotesRequest, options?: AxiosRequestConfig) {
        return MemberNoteControllerApiFp(this.configuration).getNotes(requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {MemberNoteControllerApiUpdateNoteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberNoteControllerApi
     */
    public updateNote(requestParameters: MemberNoteControllerApiUpdateNoteRequest, options?: AxiosRequestConfig) {
        return MemberNoteControllerApiFp(this.configuration).updateNote(requestParameters.memberId, requestParameters.noteId, requestParameters.memberNoteRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}
