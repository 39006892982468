/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @interface BusinessEntitySnapshotAllOf
 */
export interface BusinessEntitySnapshotAllOf {
    /**
     *
     * @type {string}
     * @memberof BusinessEntitySnapshotAllOf
     */
    'kmsKeyId'?: string;
    /**
     *
     * @type {string}
     * @memberof BusinessEntitySnapshotAllOf
     */
    'subscriptionPlan'?: BusinessEntitySnapshotAllOfSubscriptionPlanEnum;
    /**
     *
     * @type {string}
     * @memberof BusinessEntitySnapshotAllOf
     */
    'status'?: BusinessEntitySnapshotAllOfStatusEnum;
    /**
     *
     * @type {string}
     * @memberof BusinessEntitySnapshotAllOf
     */
    'pointsSymbol'?: string;
    /**
     *
     * @type {number}
     * @memberof BusinessEntitySnapshotAllOf
     */
    'pointsConversionRate'?: number;
    /**
     *
     * @type {boolean}
     * @memberof BusinessEntitySnapshotAllOf
     */
    'basicRewardsEnabled'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum BusinessEntitySnapshotAllOfSubscriptionPlanEnum {
    STARTER = 'STARTER',
    PRO = 'PRO',
    ELITE = 'ELITE'
}
/**
    * @export
    * @enum {string}
    */
export enum BusinessEntitySnapshotAllOfStatusEnum {
    ONBOARDING_ADDITIONAL_INFO = 'ONBOARDING_ADDITIONAL_INFO',
    ONBOARDING_INDUSTRY = 'ONBOARDING_INDUSTRY',
    ONBOARDING_SUBSCRIPTION = 'ONBOARDING_SUBSCRIPTION',
    ONBOARDING_POINTS = 'ONBOARDING_POINTS',
    ACTIVE = 'ACTIVE'
}


