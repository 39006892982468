/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @interface MembersAgeGenderBreakdownMetricDtoGenderGroup
 */
export interface MembersAgeGenderBreakdownMetricDtoGenderGroup {
    /**
     *
     * @type {string}
     * @memberof MembersAgeGenderBreakdownMetricDtoGenderGroup
     */
    'gender'?: MembersAgeGenderBreakdownMetricDtoGenderGroupGenderEnum;
    /**
     *
     * @type {number}
     * @memberof MembersAgeGenderBreakdownMetricDtoGenderGroup
     */
    'count'?: number;
    /**
     *
     * @type {number}
     * @memberof MembersAgeGenderBreakdownMetricDtoGenderGroup
     */
    'percentage'?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum MembersAgeGenderBreakdownMetricDtoGenderGroupGenderEnum {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER'
}


