/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ConsumerInternalDto } from './consumer-internal-dto';

/**
 *
 * @export
 * @interface MemberInternalDto
 */
export interface MemberInternalDto {
    /**
     *
     * @type {string}
     * @memberof MemberInternalDto
     */
    'id'?: string;
    /**
     *
     * @type {string}
     * @memberof MemberInternalDto
     */
    'externalId'?: string;
    /**
     *
     * @type {string}
     * @memberof MemberInternalDto
     */
    'businessEntityId'?: string;
    /**
     *
     * @type {string}
     * @memberof MemberInternalDto
     */
    'status'?: MemberInternalDtoStatusEnum;
    /**
     *
     * @type {ConsumerInternalDto}
     * @memberof MemberInternalDto
     */
    'consumer'?: ConsumerInternalDto;
    /**
     *
     * @type {string}
     * @memberof MemberInternalDto
     */
    'tierLevelId'?: string;
    /**
     *
     * @type {number}
     * @memberof MemberInternalDto
     */
    'pointsDelta'?: number;
    /**
     *
     * @type {number}
     * @memberof MemberInternalDto
     */
    'amountSpent'?: number;
    /**
     *
     * @type {string}
     * @memberof MemberInternalDto
     */
    'lastBusinessJoinAsRegular'?: string;
    /**
     *
     * @type {string}
     * @memberof MemberInternalDto
     */
    'joiningType'?: MemberInternalDtoJoiningTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof MemberInternalDto
     */
    'campaignSmsSubscribed'?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof MemberInternalDto
     */
    'campaignEmailSubscribed'?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum MemberInternalDtoStatusEnum {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DEPRECATED = 'DEPRECATED',
    FORMER = 'FORMER'
}
/**
    * @export
    * @enum {string}
    */
export enum MemberInternalDtoJoiningTypeEnum {
    IN_APP = 'IN_APP',
    BUSINESS_LANDING = 'BUSINESS_LANDING',
    IN_STORE = 'IN_STORE'
}


