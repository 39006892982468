/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConsumerDtoDefaultView } from '../models';
// @ts-ignore
import { ProblemDetail } from '../models';
/**
 * ConsumerControllerApi - axios parameter creator
 * @export
 */
export const ConsumerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [email]
         * @param {string} [phone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationCandidates: async (email?: string, phone?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/consumers/invitation-candidates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsumerControllerApi - functional programming interface
 * @export
 */
export const ConsumerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsumerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {string} [email]
         * @param {string} [phone]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvitationCandidates(email?: string, phone?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsumerDtoDefaultView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvitationCandidates(email, phone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsumerControllerApi - factory interface
 * @export
 */
export const ConsumerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsumerControllerApiFp(configuration)
    return {
        /**
         *
         * @param {ConsumerControllerApiGetInvitationCandidatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationCandidates(requestParameters: ConsumerControllerApiGetInvitationCandidatesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ConsumerDtoDefaultView> {
            return localVarFp.getInvitationCandidates(requestParameters.email, requestParameters.phone, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getInvitationCandidates operation in ConsumerControllerApi.
 * @export
 * @interface ConsumerControllerApiGetInvitationCandidatesRequest
 */
export interface ConsumerControllerApiGetInvitationCandidatesRequest {
    /**
     *
     * @type {string}
     * @memberof ConsumerControllerApiGetInvitationCandidates
     */
    readonly email?: string

    /**
     *
     * @type {string}
     * @memberof ConsumerControllerApiGetInvitationCandidates
     */
    readonly phone?: string
}

/**
 * ConsumerControllerApi - object-oriented interface
 * @export
 * @class ConsumerControllerApi
 * @extends {BaseAPI}
 */
export class ConsumerControllerApi extends BaseAPI {
    /**
     *
     * @param {ConsumerControllerApiGetInvitationCandidatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerControllerApi
     */
    public getInvitationCandidates(requestParameters: ConsumerControllerApiGetInvitationCandidatesRequest = {}, options?: AxiosRequestConfig) {
        return ConsumerControllerApiFp(this.configuration).getInvitationCandidates(requestParameters.email, requestParameters.phone, options).then((request) => request(this.axios, this.basePath));
    }
}
